<div class="container" style="margin-top:100px; margin-bottom:100px;">
  <div class="row">
    <div class="col-1"></div>
    <div class="col-4">
      <div>
        <!-- <img src="/assets/images/aedc-full-logo.jpg"> -->
      </div>
      <h1 class="text-primary font-weight-bold mt-3">Welcome</h1>
    </div>
    <div class="col-1"></div>

    <div class="col-md-4">
      <!-- REQUEST RESET -->
      <div class="card bg-primary text-white square-edges" *ngIf="!resetMode">

        <!-- <div class="card-header bg-dark text-white">Request Reset Password</div> -->
        <div class="card-body" *ngIf="!requestSuccess">
          <h2>Reset password</h2>
          <p>Please enter your username or email to request a password reset.</p>
          <form #thisForm="ngForm" class="mt-5">
            <div class="form-group">
              <label class="control-label">Username/Email</label>
              <input type="text" class="form-control" [(ngModel)]="username" id="txtUsername" name="txtUsername" required>
            </div>
            <div>
              <button type="button" class="btn btn-dark" (click)="RequestPasswordReset(thisForm,$event)" *ngIf="!processing">Request Password Reset</button>
              <span class="btn btn-dark" *ngIf="processing"><i class="fa fa-spinner fa-spin"></i> Processing...</span>
            </div>
            <div class="alert alert-danger" *ngIf="requestResponse && !requestResponse.Success">
              There was a problem requesting a password reset: <i>{{requestResponse.Message}}</i>
            </div>
          </form>
        </div>

        <div class="card-body" *ngIf="requestSuccess">
          <div class="alert alert-success">
            A password reset request has been sent to the email registered on file. Please check your SPAM if it does not appear in your email within the next 5 minutes.
          </div>
        </div>
      </div>
      <!-- /REQUEST RESET -->
      <!-- REQUEST RESET -->
      <div class="card bg-primary text-white square-edges" *ngIf="resetMode">

        <!-- <div class="card-header bg-dark text-white">Reset Password</div> -->
        <div class="card-body" *ngIf="!requestSuccess || (requestSuccess && requestError)">
          <h2>Reset password</h2>
          <p>Please enter your new password below and confirm.</p>
          <form #thisResetForm="ngForm" class="mt-5">
            <div class="form-group">
              <label class="control-label" for="txt_Password">Create password</label>
              <input type="password" id="txt_Password" name="txt_Password" class="form-control form-control-lg input-blue-border" [(ngModel)]="passwordReset.Password" pattern="^.{13,50}$" required>
              <span class="error-label">Password is required and must be a minimum length of 13 alphanumeric characters</span>
              <small class="text-white">Passwords must have a minimum length of 13 alphanumeric characters, in accordance with <i>Australian Government Information Security Manual standards</i></small>
            </div>

            <div class="form-group">
              <label class="control-label" for="txt_ConfirmPassword">Confirm password</label>
              <input type="password" id="txt_ConfirmPassword" name="txt_ConfirmPassword" class="form-control form-control-lg input-blue-border" [(ngModel)]="passwordReset.ConfirmPassword" required>
              <span class="text-danger" *ngIf="confirmPasswordError">Confirm password must match password</span>
            </div>

            <div>
              <button type="button" class="btn btn-dark" (click)="PasswordReset(thisResetForm,$event)" *ngIf="!processing">Reset Password</button>
              <span class="btn btn-dark" *ngIf="processing"><i class="fa fa-spinner fa-spin"></i> Processing...</span>
            </div>
            <div class="alert alert-danger mt-2" *ngIf="requestError">
              There was a problem processing your request. Please contact our offices.
            </div>
          </form>
        </div>

        <div class="card-body" *ngIf="requestSuccess">
          <div class="alert alert-success" *ngIf="!requestError">
            <p>Your password has been successfully reset.</p>
            <p>
              <a [routerLink]="['/login']">Proceed to Login</a>
            </p>
          </div>
        </div>

      </div>
      <!-- /REQUEST RESET -->
    </div>
  </div>
</div>
