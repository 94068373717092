<header>

  <nav class="navbar navbar-expand-lg navbar-light sticky-top">
    <div class="container-fluid">
      <a class="navbar-brand" [routerLink]="['/']">
        <img src="/assets/images/ev8-logo.png" height="55px">
      </a>

      <button id="navToggle" class="navbar-toggler" type="button" data-toggle="collapse" data-target="#sidebarMenu" aria-controls="sidebarMenu" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="navbar-collapse collapse" id="navbarSupportedContent">
        <!-- Not Logged In -->
        <ul class="navbar-nav ml-auto" *ngIf="!loggedIn">
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/login']">Sign in <i class="fa fa-sign-in"></i></a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/register']">Register <i class="far fa-user-circle"></i></a>
          </li>
        </ul>
        <!-- /Not Logged In -->

        <!-- LOGGED IN -->
        <ul class="navbar-nav ml-auto" *ngIf="loggedIn">
          <li class="nav-item dropdown float-right">
            <a class="nav-link dropdown-toggle text-dark" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <strong><i class="fa fa-user-circle"></i> {{currentUser.FirstName}} {{currentUser.LastName}}</strong>
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdown">
              <a class="dropdown-item" href="javascript:void(0)" (click)="ResetPassword()">Change Password</a>
              <a class="dropdown-item" href="javascript:void(0)" (click)="logout()"><i class="fa fa-sign-out"></i> Sign out</a>
            </div>
          </li>
        </ul>
        <!-- /LOGGED IN -->

      </div>
    </div>
  </nav>

</header>
<!-- /Grey top line -->


<!-- Content -->
<div class="h-100" *ngIf="loggedIn">
  <div class="row h-100 w-100 ml-0">

    <nav id="sidebarMenu" class="col-md-3 col-lg-2 pr-0 d-md-block bg-light sidebar collapse bg-white">
      <div class="sidebar-sticky">

        <ul class="nav flex-column" style="padding-top:10px;">
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/family']">Dashboard</a>
          </li>

          <!-- ADMIN -->
          <li class="nav-item" *ngIf="loggedIn">
            <h5 class="nav-link"><i class="fas fa-tools"></i> Administration</h5>
            <ul class="nav nav-sub flex-column">
              <li class="nav-item-sub">
                <a class="nav-link" [routerLink]="['/family']">Members</a>
              </li>
              <li class="nav-item-sub">
                <a class="nav-link" [routerLink]="['/family/bank-accounts']">Bank Accounts</a>
              </li>
              <li class="nav-item-sub">
                <a class="nav-link" [routerLink]="['/family/transaction-category-groups']">Category Groups</a>
              </li>
              <li class="nav-item-sub">
                <a class="nav-link" [routerLink]="['/family/transaction-categories']">Categories</a>
              </li>
            </ul>
          </li>
          <!-- ADMIN -->
          <!-- BUDGETS -->
          <li class="nav-item" *ngIf="loggedIn">
            <h5 class="nav-link"><i class="fa fa-dollar"></i> Budgets</h5>
            <ul class="nav nav-sub flex-column">
              <li class="nav-item-sub">
                <a class="nav-link" [routerLink]="['/budgets']">List</a>
              </li>
            </ul>
          </li>
          <!-- BUDGETS -->
          <!-- TRANSACTIONS -->
          <li class="nav-item" *ngIf="loggedIn">
            <h5 class="nav-link"><i class="fa fa-list"></i> Transactions</h5>
            <ul class="nav nav-sub flex-column">
              <li class="nav-item-sub">
                <a class="nav-link" [routerLink]="['/family/transactions-dashboard']">Dashboard</a>
              </li>
              <li class="nav-item-sub">
                <a class="nav-link" [routerLink]="['/family/import-transactions']">Import</a>
              </li>
            </ul>
          </li>
          <!-- TRANSACTIONS -->
        </ul>

        <!-- Not Logged In -->
        <ul class="navbar-nav ml-auto d-md-none d-block" *ngIf="!loggedIn">
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/login']">Sign in <i class="fa fa-sign-in"></i></a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/register']">Register <i class="far fa-user-circle"></i></a>
          </li>
        </ul>
        <!-- /Not Logged In -->

        <!-- LOGGED IN -->
        <ul class="navbar-nav ml-auto d-md-none d-block" *ngIf="loggedIn">
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle text-dark" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <strong><i class="fa fa-user-circle"></i> {{currentUser.FirstName}} {{currentUser.LastName}}</strong>
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdown">
              <a class="dropdown-item" href="javascript:void(0)" (click)="ResetPassword()">Change Password</a>
              <a class="dropdown-item" href="javascript:void(0)" (click)="logout()"><i class="fa fa-sign-out"></i> Sign out</a>
            </div>
          </li>
        </ul>
        <!-- /LOGGED IN -->


      </div>
    </nav>

    <main role="main" class="col-md-9 col-lg-10 main-bg-blue">

      <div class="container-fluid title-container">
        <h1>{{title}}</h1>
        <p>{{summary}}</p>
      </div>

      <router-outlet></router-outlet>

    </main>
  </div>


</div>
<!-- /Content -->

<!-- Footer -->
<!-- <div class="bg-light mt-3 fixed-bottom">
    <div class="container-fluid text-right">
      &copy; 2022 BMH Technology
    </div>
  </div> -->
<!-- /Footer -->
