<form #thisForm="ngForm" (keydown.enter)="PerformLogin(thisForm,$event)">
  <div class="form-group">
    <label class="control-label">Email</label>
    <input type="text" class="form-control" id="txtUsername" name="txtUsername" [(ngModel)]="request.UserName" required>
  </div>
  <div class="form-group">
    <label class="control-label">Password</label>
    <input type="password" class="form-control" id="txtPassword" name="txtPassword" [(ngModel)]="request.Password" required>
  </div>

  <div>
    <button type="button" class="btn btn-secondary" *ngIf="!processing" (click)="PerformLogin(thisForm,$event)">Login</button>
    <span class="btn btn-dark" *ngIf="processing"><i class="fa fa-spinner fa-spin"></i> Processing...</span>
    <a [routerLink]="['/reset-password']" class="btn btn-link float-right">
      Forgot Your Password?
    </a>
  </div>

  <div class="alert alert-danger mt-2" *ngIf="error!=''">{{error}}</div>
</form>
