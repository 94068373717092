import { UserModel } from './';
export class GenericResponseModel {
    Success: boolean;
    Message: string;
    User: UserModel;

    constructor() {
        this.Success = false;
        this.Message = "";
    }
}