<div class="modal-header bg-dark text-white">
  <h4 class="modal-title">Reset User Password</h4>
  <button type="button" class="close text-white" aria-label="Close" (click)="activeModal.close(null)">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form #thisForm="ngForm">
    <div class="form-group">
      <label class="control-label" for="txt_Password">New password</label>
      <input type="password" id="txt_Password" name="txt_Password" class="form-control form-control-lg input-blue-border" [(ngModel)]="request.Password" pattern="^.{13,50}$" required>
      <span class="error-label">Password is required and must be a minimum length of 13 alphanumeric characters</span>
      <small class="text-muted">Passwords must have a minimum length of 13 alphanumeric characters, in accordance with <i>Australian Government Information Security Manual standards</i></small>
    </div>

    <div class="form-group">
      <label class="control-label" for="txt_ConfirmPassword">Confirm password</label>
      <input type="password" id="txt_ConfirmPassword" name="txt_ConfirmPassword" class="form-control form-control-lg input-blue-border" [(ngModel)]="request.ConfirmPassword" [pattern]="'^'+this.request.Password+'$'" required>
      <span class="error-label">Confirm password must match password</span>
    </div>
  </form>
</div>
<div class="modal-footer">
  <div class="row w-100">
    <div class="col-6">
      <button type="button" class="btn btn-primary" (click)="Save(thisForm,$event)" *ngIf="!processing">Reset Password</button>
      <span class="btn btn-dark" *ngIf="processing"><i class="fa fa-spinner fa-spin"></i> Processing...</span>
    </div>
    <div class="col-6 text-right">
      <button type="button" class="btn btn-outline-dark ml-1" (click)="activeModal.close(null)" *ngIf="!processing">Close</button>
    </div>
  </div>
</div>
