import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable()
export class AppConfig {

    public config: Object = null;
    private env: Object = null;

    constructor(public http: HttpClient) {

    }

    /**
     * Use to get the data found in the second file (config file)
     */
    public getConfig(key: any) {
        var that = this;
        return this.config[key];
    }

    /**
     * Use to get the data found in the first file (env file)
     */
    public getEnv(key: any) {
        return this.env[key];
    }

    /**
     * This method:
     *   b) Loads "site.config.json" to get all site variables
     */
    public load() {
        let vd = environment.virtualDirectory;
        let url = vd + '/assets/data/site.config.json';
        return new Promise((resolve, reject) => {
            this.http.get(url)
                .pipe(
                    map(response => response),
                    catchError((error: HttpErrorResponse) => {
                        resolve(error);
                        return throwError(error || 'Server error');
                    })
                )
                .subscribe((responseData) => {
                    this.config = responseData;
                    resolve(true);
                });
        });
    }
    public manualLoad(): Observable<any> {
        let vd = environment.virtualDirectory;
        let url = vd + '/assets/data/site.config.json';
        return this.http.get(url)
            .pipe(map((res) => { res }));
    }
}