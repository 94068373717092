import { Injectable } from '@angular/core';
import { NgbDateAdapter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class CustomDateAdapter extends NgbDateAdapter<string> {

    readonly DELIMITER = '-';

    fromModel(value: string | null): NgbDateStruct | null {

        if (value) {
            let date = value.split(this.DELIMITER);
            return {
                day: parseInt(date[2], 10),
                month: parseInt(date[1], 10),
                year: parseInt(date[0], 10)
            };
        }
        return null;
    }

    toModel(date: NgbDateStruct | null): string | null {

        if (date != null) {
            var year = date.year.toString();
            var month = date.month.toString();
            var day = date.day.toString();

            if (month.length < 2) {
                month = '0' + month;
            }
            return year + this.DELIMITER + month + this.DELIMITER + day;
        }

        return null;
    }
}
