import { Component, Input, Output, EventEmitter } from '@angular/core';
import { forkJoin } from 'rxjs';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StateService, AuthService } from '../../../services';
import { ResetPasswordRequestModel, UserModel } from '../../../models';
import { FormFunctions, CustomDateParserFormatter } from '../../../functions';
import { Validations } from '../../../constants'

@Component({
    selector: 'modal-reset-password',
    styleUrls: ['./modal-reset-password.component.scss'],
    templateUrl: 'modal-reset-password.component.html'
})
export class ModalResetPasswordComponent {
    request: ResetPasswordRequestModel = new ResetPasswordRequestModel();
    processing: boolean = false;
    error: boolean = false;
    errorMessage: string = "";

    constructor(public activeModal: NgbActiveModal, public stateService: StateService, public validations: Validations, public formFunctions: FormFunctions, public authService: AuthService) {
        let me = this;
    }
    ngOnInit() {
        let me = this;
    }
    Save(form, e) {
        var me = this;

        if (me.formFunctions.isFormValid(form, e)) {
            me.processing = true;
            me.authService.UpdatePassword(me.request).subscribe(data => {
                me.processing = false;
                me.activeModal.close(true);
            }, err => {
                me.processing = false;
                me.error = true;
            });
        }
    }
}