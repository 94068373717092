export class AddressObjectModel {
    AddressLine1: string;
    AddressLine2: string;
    AddressLine3: string;
    Suburb: string;
    State: string;
    Postcode: string;

    constructor() {
        this.AddressLine1 = "";
        this.AddressLine2 = "";
        this.AddressLine3 = "";
        this.Suburb = "";
        this.State = "";
        this.Postcode = "";
    }
}