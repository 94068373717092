import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';

import { StateService, AuthService } from '../../../services';
import { UserModel } from '../../../models';
import { AppConfig } from '../../../config';

@Component({
    selector: 'page-home',
    templateUrl: './page-home.component.html',
    styleUrls: ['./page-home.component.scss']
})
export class PageHomeComponent implements OnInit {
    loggedIn: boolean = false;
    currentUser: UserModel;

    constructor(private appConfig: AppConfig, public stateService: StateService, public sanitizer: DomSanitizer, public authService: AuthService) {
        var me = this;

        me.currentUser = me.stateService.userModel;
        me.checkLoggedIn();

        me.stateService.userModel$.subscribe(data => {
            me.currentUser = data;
            me.loggedIn = data != null;
            me.checkLoggedIn();
        });

    }

    ngOnInit() {
        var me = this;
    }
    checkLoggedIn() {
        let me = this;
        me.authService.IsLoggedOn().subscribe(data => {
            // Logged In
            me.loggedIn = true;
        }, err => {
            // Not logged in
            me.loggedIn = false;
        });
    }

}
