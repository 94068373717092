import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import {
    SignedInGuard,
    AdminRoleGuard
} from '../guards';

import {
    PageHomeComponent,
    PageForgotPasswordComponent,
    PageLoginComponent
} from './pages'

const routes: Routes = [
    { path: '', component: PageHomeComponent, data: { title: 'Home' } },
    { path: 'login', component: PageLoginComponent, data: { title: 'Login' } },
    { path: 'reset-password', component: PageForgotPasswordComponent, data: { title: 'Forgot password' } },
    { path: '**', component: PageHomeComponent, data: { title: 'Not Found'} }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class GeneralRoutingModule { }
