  <div class="row h-100">
    <div class="col-md-6 p-md-5">
      <!-- REQUEST RESET -->
      <div class="" *ngIf="!resetMode">

        <div class="">
          <h3 class="text-primary">Request Reset Password</h3>
        </div>
        <div class="" *ngIf="!requestSuccess">
          <p>Please enter your username or email to request a password reset.</p>
          <form #thisForm="ngForm">
            <div class="form-group">
              <label class="control-label">Username/Email</label>
              <input type="text" class="form-control" [(ngModel)]="username" id="txtUsername" name="txtUsername" required>
            </div>
            <div>
              <button class="btn btn-lg btn-primary w-100" (click)="RequestPasswordReset(thisForm,$event)" *ngIf="!processing">Request Password Reset</button>
              <span class="btn btn-lg btn-dark w-100" *ngIf="processing"><i class="fa fa-spinner fa-spin"></i> Processing...</span>
            </div>
            <div class="alert alert-danger mt-2" *ngIf="requestError">
              There was a problem processing your request. Please contact our offices.
            </div>
          </form>
        </div>

        <div class="" *ngIf="requestSuccess">
          <div class="alert alert-success">
            A password reset request has been sent to the email registered on file. Please check your SPAM if it does not appear in your email within the next 5 minutes.
          </div>
        </div>
      </div>
      <!-- /REQUEST RESET -->
      <!-- REQUEST RESET -->
      <div class="" *ngIf="resetMode">

        <div class="">
          <h3 class="text-primary">Reset Password</h3>
        </div>
        <div class="" *ngIf="!requestSuccess || (requestSuccess && requestError)">
          <p>Please enter your new password below and confirm.</p>
          <form #thisResetForm="ngForm">
            <div class="form-group">
              <label class="control-label">Password</label>
              <input type="password" class="form-control" [(ngModel)]="passwordReset.Password" id="txtPassword" name="txtPassword" required>
            </div>
            <div class="form-group">
              <label class="control-label">Confirm</label>
              <input type="password" class="form-control" [(ngModel)]="passwordReset.ConfirmPassword" id="txtConfirmPassword" name="txtConfirmPassword" required [pattern]="'^'+this.passwordReset.Password+'$'">
            </div>
            <div>
              <button class="btn btn-lg btn-primary w-100" (click)="PasswordReset(thisResetForm,$event)" *ngIf="!processing">Reset Password</button>
              <span class="btn btn-lg btn-dark w-100" *ngIf="processing"><i class="fa fa-spinner fa-spin"></i> Processing...</span>
            </div>
            <div class="alert alert-danger mt-2" *ngIf="requestError">
              There was a problem processing your request. Please contact our offices.
            </div>
          </form>
        </div>

        <div class="" *ngIf="requestSuccess">
          <div class="alert alert-success" *ngIf="!requestError">
            <p>Your password has been successfully reset.</p>
            <p>
              <a [routerLink]="['/login']">Proceed to Login</a>
            </p>
          </div>
        </div>

      </div>
      <!-- /REQUEST RESET -->
    </div>
  </div>
