import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { GeneralLayoutComponent } from './shared/layouts';

import {
  SignedInGuard,
  AdminRoleGuard,
  OrganisationRoleGuard
} from './guards';

import {
  PageLoginComponent,
  PageForgotPasswordComponent
} from './pages';

const routes: Routes = [
  { path: '', component: PageLoginComponent, data: { title: 'My Family Finance' } },
  { path: 'login', component: PageLoginComponent, data: { title: 'My Family Finance' } },
  { path: 'reset-password', component: PageForgotPasswordComponent, data: { title: 'Reset Password' } },
  { path: 'reset-password/:token', component: PageForgotPasswordComponent, data: { title: 'Reset Password' } },

  { path: 'admin', loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule), canActivate: [AdminRoleGuard] },
  { path: 'family', component: GeneralLayoutComponent, loadChildren: () => import('./family/family.module').then(m => m.FamilyModule), canActivate: [SignedInGuard] },
  { path: 'budgets', component: GeneralLayoutComponent, loadChildren: () => import('./budget/budget.module').then(m => m.BudgetModule), canActivate: [SignedInGuard] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
