import { BrowserModule } from '@angular/platform-browser';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgModule, APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

// Modules
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbModule, NgbTooltipConfig } from '@ng-bootstrap/ng-bootstrap';
import { NgxEditorModule } from 'ngx-editor';
import { NgbDateAdapter, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { AgGridModule } from 'ag-grid-angular';
import { NgxColorsModule } from 'ngx-colors';

// Strategies
import { LocationStrategy, HashLocationStrategy, PathLocationStrategy } from '@angular/common';

// App Settings
import { AppConfig, ServiceHelper } from './config';

// Modules
import { SharedModule } from './shared/shared.module';
import { GeneralModule } from './general/general.module';

// App Guards
import {
  SignedInGuard,
  AdminRoleGuard,
  OrganisationRoleGuard
} from './guards';

// App Services
import {
  AuthService,
  RoleService,
  StateService,
  GenericDataService,
  AnalyticsService,
  ScriptService,
  SharedService,
  BankService,
  FamilyBankAccountService,
  FamilyTransactionService,
  FamilyTransactionCategoryService,
  FamilyTransactionCategoryGroupService,
  BudgetService,
  BudgetItemService
} from './services'

import {
  Validations
} from './constants';

import {
  FormFunctions,
  CustomDateAdapter,
  CustomDateParserFormatter
} from './functions';

import {
  PageLoginComponent,
  PageForgotPasswordComponent
} from './pages'

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import 'hammerjs';

// Load the app config in a factory
export function appConfigFactory(provider: AppConfig) {
  return () => provider.load();
}

@NgModule({
  declarations: [
    AppComponent,
    PageLoginComponent,
    PageForgotPasswordComponent
  ],
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    AgGridModule,
    NgxColorsModule,
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    NgSelectModule,
    NgbModule,
    NgxEditorModule,
    SharedModule,
    GeneralModule
  ],
  providers: [
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    { provide: APP_INITIALIZER, useFactory: appConfigFactory, deps: [AppConfig], multi: true },
    { provide: NgbDateAdapter, useClass: CustomDateAdapter },
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
    NgbTooltipConfig,
    DatePipe,
    SignedInGuard,
    AdminRoleGuard,
    OrganisationRoleGuard,
    AppConfig,
    Validations,
    FormFunctions,
    ServiceHelper,
    AuthService,
    RoleService,
    StateService,
    GenericDataService,
    AnalyticsService,
    ScriptService,
    SharedService,
    BankService,
    FamilyBankAccountService,
    FamilyTransactionService,
    FamilyTransactionCategoryService,
    FamilyTransactionCategoryGroupService,
    BudgetService,
    BudgetItemService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
