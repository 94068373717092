import { RoleModel } from './';

export class UserModel {
    Id: number;
    Username: string;
    FirstName: string;
    LastName: string;
    Email: string;
    UniqueToken: string;
    State: string;
    Roles: RoleModel[];
    Token: string;
    LastLoginDateTime: Date;


    constructor() {
        this.Username = "";
        this.FirstName = "";
        this.LastName = "";
        this.Email = "";
        this.UniqueToken = "";
        this.State = "";
        this.Roles = [];
        this.Token = "";
    }
}