export class ResetPasswordRequestModel {
    UserId: number;
    ResetToken: string;
    Password: string;
    ConfirmPassword: string;

    constructor() {
        this.ResetToken = "";
        this.Password = "";
        this.ConfirmPassword = "";
    }
}