import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { ServiceHelper } from '../config';
import { Authenticate, UserModel, ResetPasswordRequestModel, GenericResponseModel } from '../models';
import { StateService } from '../services';
import { AppConfig } from '../config';

@Injectable()
export class AuthService {

    constructor(public http: HttpClient, public serviceHelper: ServiceHelper, private appConfig: AppConfig) {
        var that = this;
    }
    FindAll(): Observable<any> {
        var headers = this.serviceHelper.GetHeaders();

        return this.http.get<UserModel[]>(this.appConfig.getConfig('apiUrl') + '/users', { withCredentials: true, headers: headers });
    }
    FindByRole(roleName: string): Observable<any> {
        var headers = this.serviceHelper.GetHeaders();

        return this.http.get<UserModel[]>(this.appConfig.getConfig('apiUrl') + '/users/by-role/' + roleName, { withCredentials: true, headers: headers });
    }
    FindByToken(token: string): Observable<any> {
        var headers = this.serviceHelper.GetHeaders();

        return this.http.get<UserModel>(this.appConfig.getConfig('apiUrl') + '/users/by-token/' + token, { withCredentials: true, headers: headers });
    }
    FindMyUser(): Observable<any> {
        var headers = this.serviceHelper.GetHeaders();

        return this.http.get<UserModel>(this.appConfig.getConfig('apiUrl') + '/users/my-user', { withCredentials: true, headers: headers });
    }
    Login(request: Authenticate): Observable<any> {
        var headers = this.serviceHelper.GetHeaders();

        var params = JSON.stringify(request);

        return this.http.post<UserModel>(this.appConfig.getConfig('apiUrl') + '/users/authenticate', params, { withCredentials: true, headers: headers });
    }
    Logout(): Observable<any> {
        var headers = this.serviceHelper.GetHeaders();

        var params = JSON.stringify({});

        return this.http.post(this.appConfig.getConfig('apiUrl') + '/users/logout', params, { withCredentials: true, headers: headers });
    }
    IsLoggedOn(): Observable<any> {
        var headers = this.serviceHelper.GetHeaders();

        var params = JSON.stringify({});

        return this.http.get<UserModel>(this.appConfig.getConfig('apiUrl') + '/users/isloggedon', { withCredentials: true, headers: headers });
    }
    ResetPasswordRequest(userNameEmail: string): Observable<any> {
        var headers = this.serviceHelper.GetHeaders();

        var request = new Authenticate();
        request.UserName = userNameEmail;

        var params = JSON.stringify(request);

        return this.http.post(this.appConfig.getConfig('apiUrl') + '/users/request-password-reset', params, { withCredentials: true, headers: headers });
    }
    ResetPassword(request: ResetPasswordRequestModel): Observable<any> {
        var headers = this.serviceHelper.GetHeaders();

        var params = JSON.stringify(request);

        return this.http.post(this.appConfig.getConfig('apiUrl') + '/users/password-reset', params, { withCredentials: true, headers: headers });
    }
    UpdatePassword(request: ResetPasswordRequestModel): Observable<any> {
        var headers = this.serviceHelper.GetHeaders();

        var params = JSON.stringify(request);

        return this.http.post(this.appConfig.getConfig('apiUrl') + '/users/update-password', params, { withCredentials: true, headers: headers });
    }
    Add(item): Observable<any> {
        var headers = this.serviceHelper.GetHeaders();

        let params = JSON.stringify(item);

        return this.http.post<UserModel>(this.appConfig.getConfig('apiUrl') + '/users', params, { withCredentials: true, headers: headers });
    }

    Update(item): Observable<any> {
        var headers = this.serviceHelper.GetHeaders();

        let params = JSON.stringify(item);

        return this.http.post<UserModel>(this.appConfig.getConfig('apiUrl') + '/users/' + item.Id, params, { withCredentials: true, headers: headers });
    }
}