import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'loading',
    template:
        `
    <div class="row">
        <div class="col-sm-12 text-center h-100 mt-8">
            <i class="fa fa-5x fa-spin fa-circle-notch"></i>
            <h4 class="mt-4">{{displayText}}</h4>
        </div>
    </div>
  `
})
export class LoadingComponent {
    @Input() displayText: string = 'Loading... please wait';

    constructor() { }
}



