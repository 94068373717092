import { Injectable } from '@angular/core';

@Injectable()
export class FormFunctions {
    public isFormValid(form, e) {
        // debugger;
        if (form.form.invalid) {
            let formGroup = form.form;
            // form.form.markAsTouched();
            Object.keys(formGroup.controls).map(x => formGroup.controls[x]).forEach(control => {
                control.markAsTouched();

                // if (control.controls) {
                //     this.markFormGroupTouched(control);
                // }
            });
            // Object.keys(form.form.controls).forEach(key => {
            //     form.form.get(key).markAsTouched();
            // });
        }
        else {
            form.form.markAsUntouched();
        }
        return !form.form.invalid;
    }
    public DeepCopy(obj) {
        var copy;

        // Handle the 3 simple types, and null or undefined
        if (null == obj || "object" != typeof obj) return obj;

        // Handle Date
        if (obj instanceof Date) {
            copy = new Date();
            copy.setTime(obj.getTime());
            return copy;
        }

        // Handle Array
        if (obj instanceof Array) {
            copy = [];
            for (var i = 0, len = obj.length; i < len; i++) {
                copy[i] = this.DeepCopy(obj[i]);
            }
            return copy;
        }

        // Handle Object
        if (obj instanceof Object) {
            copy = {};
            for (var attr in obj) {
                if (obj.hasOwnProperty(attr)) copy[attr] = this.DeepCopy(obj[attr]);
            }
            return copy;
        }

        throw new Error("Unable to copy obj! Its type isn't supported.");
    }

}