export class Authenticate {
    UserName: string;
    Password: string;
    UniqueId: string;

    constructor() {
        this.UserName = "";
        this.Password = "";
        this.UniqueId = "";
    }
}