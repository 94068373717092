import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { NgbDateAdapter, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';

import { SharedModule } from '../shared/shared.module';
import { GeneralRoutingModule } from './general-routing.module';
import { AgGridModule } from 'ag-grid-angular';

import {
    FormFunctions,
    CustomDateAdapter,
    CustomDateParserFormatter
} from '../functions';

import {
    PageHomeComponent,
    PageForgotPasswordComponent,
    PageLoginComponent
} from './pages';

@NgModule({
    imports: [
        RouterModule,
        CommonModule,
        FormsModule,
        NgSelectModule,
        NgbModule,
        GooglePlaceModule,
        SharedModule,
        GeneralRoutingModule,
        AgGridModule
    ],
    declarations: [
        PageHomeComponent,
        PageForgotPasswordComponent,
        PageLoginComponent
    ],
    exports: [
        PageHomeComponent,
        PageForgotPasswordComponent,
        PageLoginComponent
    ],
    providers: [
        { provide: NgbDateAdapter, useClass: CustomDateAdapter },
        { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
        FormFunctions
    ]
})
export class GeneralModule {

}