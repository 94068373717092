import { BaseModel, BudgetModel, FamilyTransactionCategoryModel } from './';

export class BudgetItemModel extends BaseModel {
    BudgetId: number = 0;
    FamilyTransactionCategoryId: number = 0;
    Description: string = "";
    Amount: number = 0;
    FrequencyPerYear: number = 0;

    CalculatedAmountAnnually: number;
    CalculatedAmountWeekly: number;
    CalculatedAmountFortnightly: number;
    CalculatedAmountMonthly: number;

    Budget: BudgetModel;
    FamilyTransactionCategory: FamilyTransactionCategoryModel;
}