import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { StateService, AuthService, RoleService } from '../../services';
import { Authenticate, RoleModel } from '../../models';
import { FormFunctions } from '../../functions';

@Component({
    selector: 'page-login',
    templateUrl: './page-login.component.html',
    styleUrls: ['./page-login.component.scss']
})
export class PageLoginComponent implements OnInit {
    request: Authenticate = new Authenticate();
    status: string = "";
    uniqueToken: string = "";
    isTokenLogin: boolean = false;
    processing: boolean = false;
    loginByCode: boolean = false;

    constructor(public router: Router, private activatedRoute: ActivatedRoute, public stateService: StateService, public authService: AuthService, public formFunctions: FormFunctions, public roleService: RoleService) {
        var me = this;
        me.status = me.activatedRoute.snapshot.params.status;
        me.uniqueToken = me.activatedRoute.snapshot.params.uniqueToken;
        me.isTokenLogin = me.activatedRoute.snapshot.params.uniqueToken != null;
    }

    ngOnInit() {
        var me = this;
        if (me.isTokenLogin) {
            me.TokenLogin();
        }
    }
    loginCallback(userId) {
        var me = this;
        me.processing = true;
        me.roleService.FindMyRoles().subscribe(data => {
            me.processing = false;
            if (data.length) {
                let role: RoleModel = data[0];
                let roleName = role.Name.toLowerCase();
                me.navigateHome(roleName);
            }
        });
    }
    navigateHome(roleName) {
        let me = this;
        me.router.navigate(['/family']);
    }
    TokenLogin() {
        let me = this;
        let tokenRequest = new Authenticate();
        tokenRequest.UniqueId = me.uniqueToken;
        me.authService.Login(tokenRequest).subscribe(data => {
            me.stateService.changeUserModel(data);
            me.roleService.FindMyRoles().subscribe(data => {
                me.processing = false;
                if (data.length) {
                    let role: RoleModel = data[0];
                    let roleName = role.Name.toLowerCase();
                    me.navigateHome(roleName);
                }
            });
        });
    }
}
