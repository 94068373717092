import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { ServiceHelper } from '../config';
import { FamilyTransactionCategoryModel } from '../models';
import { AppConfig } from '../config';

@Injectable()
export class FamilyTransactionCategoryService {
    readonly baseEndpoint: string = '/family-transaction-categories/';

    constructor(public http: HttpClient, public serviceHelper: ServiceHelper, private appConfig: AppConfig) {
        var that = this;
    }

    FindAll(): Observable<any> {
        var headers = this.serviceHelper.GetHeaders();

        return this.http.get<FamilyTransactionCategoryModel[]>(this.appConfig.getConfig('apiUrl') + this.baseEndpoint, { withCredentials: true, headers: headers });
    }
    FindById(id: number): Observable<any> {
        var headers = this.serviceHelper.GetHeaders();

        return this.http.get<FamilyTransactionCategoryModel>(this.appConfig.getConfig('apiUrl') + this.baseEndpoint + id, { withCredentials: true, headers: headers });
    }
    Create(item: FamilyTransactionCategoryModel): Observable<any> {
        var headers = this.serviceHelper.GetHeaders();

        let params = JSON.stringify(item);

        return this.http.post<FamilyTransactionCategoryModel>(this.appConfig.getConfig('apiUrl') + this.baseEndpoint, params, { withCredentials: true, headers: headers });
    }
    Update(item: FamilyTransactionCategoryModel): Observable<any> {
        var headers = this.serviceHelper.GetHeaders();
        if (!item.Id) {
            throw new Error('Missing Id');
        }

        let params = JSON.stringify(item);

        return this.http.post<FamilyTransactionCategoryModel>(this.appConfig.getConfig('apiUrl') + this.baseEndpoint + item.Id, params, { withCredentials: true, headers: headers });
    }
    Delete(id: number): Observable<any> {
        var headers = this.serviceHelper.GetHeaders();

        return this.http.delete<boolean>(this.appConfig.getConfig('apiUrl') + this.baseEndpoint + id, { withCredentials: true, headers: headers });
    }
    Remove(id: number): Observable<any> {
        var headers = this.serviceHelper.GetHeaders();
        let params = JSON.stringify(id);
        return this.http.post<any>(this.appConfig.getConfig('apiUrl') + this.baseEndpoint + `remove/${id}`, params, { withCredentials: true, headers: headers });
    }
}