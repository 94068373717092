<main class="container-fluid d-flex h-100 flex-column">
  <div class="row h-100">
    <div class="col-12 col-md-5 p-md-5">
      <div class="row mt-md-5">
        <div class="col-12 text-center text-md-right">
          <!-- <img class="img-responsive" style="max-width:100%;" src="/assets/images/ev8-logo.png"> -->
        </div>
      </div>
    </div>
    <div class="col-12 col-md-7 p-md-5 bg-light text-dark">
      <div class="row mt-5">
        <div class="col-12 col-xl-6">
          <div class="login-panel">
            <h3 class="text-primary">Control Panel</h3>
            <login id="wsLogin" (onSuccess)="loginCallback($event)" *ngIf="!isTokenLogin"></login>
            <loading *ngIf="isTokenLogin" displayText="Authenticating Token ... Please wait"></loading>
          </div>

        </div>
      </div>
    </div>
  </div>
</main>

