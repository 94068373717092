import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

import {
    StateService,
    AuthService,
    AnalyticsService
} from '../../../services';
import { AppConfig } from '../../../config';
import { UserModel } from '../../../models';
import { ModalResetPasswordComponent } from '../../../shared/modals'
import { forkJoin } from 'rxjs';

@Component({
    selector: 'general-layout',
    styleUrls: ['./general-layout.component.scss'],
    templateUrl: './general-layout.component.html'
})
export class GeneralLayoutComponent implements OnInit {
    title = '';
    summary = '';
    dataCacheComplete: boolean = false;
    loggedIn: boolean = false;
    currentUser: UserModel;
    hideBanner: boolean = false;
    adminMenuMode: string = '';
    pageRef: string = '';
    appVersion: string = "2023-06-15";

    constructor(private appConfig: AppConfig, private router: Router, private activatedRoute: ActivatedRoute, private titleService: Title, private modalService: NgbModal, private analyticsService: AnalyticsService, public stateService: StateService, public authService: AuthService) {
        var me = this;

        // Page Title
        me.title = me.stateService.pageTitle;
        me.stateService.pageTitle$.subscribe(data => {
            me.title = data;
        });
        // Page Summary
        me.summary = me.stateService.pageSummary;
        me.stateService.pageSummary$.subscribe(data => {
            me.summary = data;
        });
        // Page Reference
        me.pageRef = me.stateService.pageRef;
        me.stateService.pageRef$.subscribe(data => {
            me.pageRef = data;
        });

        me.LoadCacheData();

        me.currentUser = me.stateService.userModel;
        me.checkLoggedIn();

        me.stateService.userModel$.subscribe(data => {
            me.currentUser = data;
            me.loggedIn = data != null;
            me.checkLoggedIn();
        });

        router.events.subscribe((val) => {
            // Analytics
            if ((val instanceof NavigationEnd)) {
                this.analyticsService.routeChange(val.url);
            }
            // Set the title
            var titleArr = me.getTitle(router.routerState, router.routerState.root);
            var newTitle = titleArr[titleArr.length - 1];
            me.stateService.changePageTitle(newTitle);
            // Set the Page Ref
            var pageRefArr = me.getPageRef(router.routerState, router.routerState.root);
            var newPageRef = pageRefArr[pageRefArr.length - 1];
            me.stateService.changePageRef(newPageRef);
            // Set the show banner
            var hideBannerArr = me.getHideBanner(router.routerState, router.routerState.root);
            var newHideBanner = hideBannerArr[hideBannerArr.length - 1];
            me.hideBanner = newHideBanner;
            // Set the Admin Menu
            var showAdminMenuArr = me.getAdminMenuMode(router.routerState, router.routerState.root);
            var newAdminMenu = showAdminMenuArr[showAdminMenuArr.length - 1];
            me.adminMenuMode = newAdminMenu;

            // Check if toggler still open
            let element = document.getElementById('navToggle');
            if (element) {
                let toggleElement: HTMLButtonElement = <HTMLButtonElement>(element);
                if (toggleElement.getAttribute('aria-expanded') == 'true') {
                    toggleElement.click();
                }
            }
        });
    }

    ngOnInit() {
        let me = this;
    }
    public setTitle(newTitle: string) {
        // For the H1
        this.title = newTitle;
        // For the Browser Title
        this.titleService.setTitle(newTitle);
    }
    getTitle(state, parent) {
        var data = [];
        if (parent && parent.snapshot.data && parent.snapshot.data.title) {
            data.push(parent.snapshot.data.title);
        }

        if (state && parent) {
            data.push(... this.getTitle(state, state.firstChild(parent)));
        }
        return data;
    }
    getPageRef(state, parent) {
        var data = [];
        if (parent && parent.snapshot.data && parent.snapshot.data.pageRef) {
            data.push(parent.snapshot.data.pageRef);
        }

        if (state && parent) {
            data.push(... this.getPageRef(state, state.firstChild(parent)));
        }
        return data;
    }
    getHideBanner(state, parent) {
        var data = [];
        if (parent && parent.snapshot.data && parent.snapshot.data.hideBanner) {
            data.push(parent.snapshot.data.hideBanner);
        }

        if (state && parent) {
            data.push(... this.getHideBanner(state, state.firstChild(parent)));
        }
        return data;
    }
    getAdminMenuMode(state, parent) {
        var data = [];
        if (parent && parent.snapshot.data && parent.snapshot.data.adminMenuMode) {
            data.push(parent.snapshot.data.adminMenuMode);
        }

        if (state && parent) {
            data.push(... this.getAdminMenuMode(state, state.firstChild(parent)));
        }
        return data;
    }
    logout() {
        let me = this;
        // Get rid of token will create logout
        me.stateService.changeUserModel(null);
        me.router.navigate(['/']);
        // me.authService.Logout().subscribe(data => {
        //   me.stateService.changeUserModel(null);
        // });
    }
    checkLoggedIn() {
        let me = this;
        me.authService.IsLoggedOn().subscribe(data => {
            // Logged In
            me.loggedIn = true;
        }, err => {
            // Not logged in
            me.loggedIn = false;
        });
    }
    isInRole(roleName: string) {
        let me = this;
        if (!me.currentUser) {
            return false;
        }

        var role = me.currentUser.Roles.filter(o => o.Name == roleName);
        return role.length > 0;
    }
    roleCount() {
        let me = this;
        if (!me.currentUser) {
            return 0;
        }

        return me.currentUser.Roles.length;
    }

    ResetPassword() {
        let me = this;
        const modalRef = me.modalService.open(ModalResetPasswordComponent, {
            scrollable: true
        });

    }

    LoadCacheData() {
        let me = this;

        me.dataCacheComplete = false;
        me.dataCacheComplete = true;

        forkJoin([

        ]).subscribe(([]) => {
            me.dataCacheComplete = true;
        });
    }

}
