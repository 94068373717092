import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { AgGridModule } from 'ag-grid-angular';

import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { NgbDateAdapter, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import {
    FormFunctions,
    CustomDateAdapter,
    CustomDateParserFormatter
} from '../functions';

import {
    LoadingComponent,
    LoginComponent,
    AddressAutoCompleteComponent,
} from './components';

import {
    ModalResetPasswordComponent,
} from './modals';

import {
    GeneralLayoutComponent
} from './layouts';

import {
    DateSuffixPipe
} from './pipes';

@NgModule({
    imports: [
        RouterModule,
        CommonModule,
        FormsModule,
        NgSelectModule,
        NgbModule,
        GooglePlaceModule,
        AgGridModule
    ],
    declarations: [
        LoadingComponent,
        LoginComponent,
        AddressAutoCompleteComponent,
        ModalResetPasswordComponent,
        GeneralLayoutComponent,
        DateSuffixPipe,
    ],
    exports: [
        LoadingComponent,
        LoginComponent,
        AddressAutoCompleteComponent,
        ModalResetPasswordComponent,
        GeneralLayoutComponent,
        DateSuffixPipe,
    ],
    providers: [
        { provide: NgbDateAdapter, useClass: CustomDateAdapter },
        { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
        FormFunctions
    ]
})
export class SharedModule {

}