import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { StateService, AuthService } from '../../../services';
import { AddressObjectModel } from '../../../models';
import { AppConfig } from '../../../config';


@Component({
    selector: 'address-auto-complete',
    templateUrl: './address-auto-complete.component.html',
    styleUrls: ['./address-auto-complete.component.scss']
})
export class AddressAutoCompleteComponent implements OnInit {
    @Output() updatedEvent: any = new EventEmitter();

    constructor(private appConfig: AppConfig, public stateService: StateService, public sanitizer: DomSanitizer, public authService: AuthService, public modalService: NgbModal) {
        var me = this;
    }

    ngOnInit() {
        var me = this;

        // setTimeout(function () {
        //     let elems = document.getElementsByName('address-auto-complete');
        //     if (elems.length) {
        //         debugger;
        //         let elem = elems[0];
        //         elem.setAttribute('autocomplete', 'no-thanks');
        //     }
        // }, 100);
    }
    handleAddressChange(address: any) {
        let me = this;
        // Do some stuff
        let streetNumber = "";
        let addrLine1 = "";
        let addrLine2 = "";
        let suburb = "";
        let state = "";
        let postcode = "";

        let components = address.address_components;
        for (let i = 0; i < components.length; i++) {
            let thisComponent = components[i];
            if (thisComponent.types.filter(o => o == 'street_number').length) {
                streetNumber = thisComponent.long_name;
            }
            else if (thisComponent.types.filter(o => o == 'route').length) {
                addrLine1 = thisComponent.long_name;
            }
            else if (thisComponent.types.filter(o => o == 'locality').length) {
                suburb = thisComponent.long_name;
            }
            else if (thisComponent.types.filter(o => o == 'administrative_area_level_1').length) {
                state = thisComponent.short_name;
            }
            else if (thisComponent.types.filter(o => o == 'postal_code').length) {
                postcode = thisComponent.long_name;
            }
        }

        let addressObj = new AddressObjectModel();
        addressObj.AddressLine1 = streetNumber + ' ' + addrLine1;
        addressObj.Suburb = suburb;
        addressObj.State = state;
        addressObj.Postcode = postcode;

        me.updatedEvent.emit(addressObj);
    }
}
