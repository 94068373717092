import { Component, Injectable,Input,Output,EventEmitter } from '@angular/core';

@Injectable()
export class SharedService {
  @Output() fire: EventEmitter<any> = new EventEmitter();
  checked: Boolean = false;

   constructor() {
     console.log('shared service started');
   }

   change() {
    console.log('change started'); 
    this.checked = !this.checked;
    this.fire.emit(this.checked);
   }

   getEmittedValue() {
     return this.fire;
   }

} 