import { BaseModel } from './';

export class FamilyTransactionModel extends BaseModel {
    FamilyBankAccountId: number = null;
    FamilyTransactionImportBatchId: number = null;
    FamilyTransactionCategoryId: number = null;
    TransactionDate: Date;
    Amount: number;
    Description: string = "";
    ReceiptNumber: string = "";
}